<template>
  <div class="container">
    <h1>Documentación API SPDY</h1>
    <h2>Tabla de Estados</h2>
    <table border="1" style="margin-bottom: 1rem;">
      <thead>
        <tr>
          <th>Código</th>
          <th>Nombre</th>
          <th>Descripción</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(state, index) in states" :key="index">
          <td>{{ state.code }}</td>
          <td>{{ state.name }}</td>
          <td>{{ state.description }}</td>
        </tr>
      </tbody>
    </table>
    <div v-for="(endpoint, index) in endpoints" :key="index">
      <h2>{{ endpoint.title }}</h2>
      <p>
        <strong>Endpoint:</strong>
        <p></p>
        <code class="code2">{{ endpoint.method }} {{ endpoint.url }}</code>
      </p>
      <pre><code>{{ endpoint.request }}</code></pre>
      
      <p><strong>Respuesta:</strong></p>
      <p></p>
      <pre><code>{{ endpoint.response }}</code></pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntegrationDocument",
  data() {
    return {
      endpoints: [
        {
          title: "Cotización de Servicio",
          method: "POST",
          url: "/v1/service/quote",
          request: `curl --location 'https://api.dev.spdy.com.co/v1/service/quote' \\
            --header 'x-access-token: XXXXXXXXXXXXXXXXXX' \\
            --header 'Content-Type: application/json' \\
            --data '{
              "pointSale": "#53:101",
              "destination": {
                "lat": 4.656977185992077,
                "lng": -74.06043781688864
              },
              "returned": false,
              "multiDestiny": [],
              "flag": {
                "multistop": false,
                "picking": false
              },
              "totalPrice": 5000
            }'`,
          response: `{
            "distanceMatrix": {
              "distance": { "text": "0.0 km", "value": 0 },
              "duration": { "text": "0 min", "value": 0 },
              "status": "OK"
            },
            "costDelivery": 50,
            "detailsCommerce": {
              "priceAdd": 0,
              "minutesAdd": 0,
              "kmAdd": 0,
              "km": 1,
              "minutes": 500,
              "base": 0,
              "minutesApplied": 0,
              "kmPriceAdditional": 100000,
              "minutesPriceAdditional": 100000,
              "priceReturn": 0,
              "additionalStopPrice": 0,
              "insurance": "1%",
              "insuranceValue": 50,
              "stopsNumber": -1,
              "pricePicking": 0
            }
          }`,
        },
        {
          title: "Creación de Servicio",
          method: "POST",
          url: "/v1/service/public",
          request: `curl --location 'https://api.dev.spdy.com.co/v1/service/public' \\
            --header 'x-access-token: XXXXXXXXXXXXXXXXXXXXXXX' \\
            --header 'Content-Type: application/json' \\
            --data '{
              "returned": false,
              "address": "carrera 13 63 39, ",
              "observations": "",
              "city": {
                  "rid": "-",
                  "code": "151",
                  "name": "Bogotá"
              },
              "pointSale": "#53:101",
              "pointSaleCode": "001",
              "client": {
                  "id": "c1498ce6-d59d-4af7-91f2-c95d61658950",
                  "name": "prueba prueba",
                  "email": "prueba1@gmail.com",
                  "phone": "3138005229",
                  "deliverix": "***inout-7323523***",
                  "inout": "7323523"
              },
              "items": [
                  {
                      "amount": 1,
                      "description": "Soy un producto con nombre largo para ver",
                      "comment": "",
                      "subtotal": 8000,
                      "code": "",
                      "image": {
                          "xs": "https://cdn.inoutdelivery.com/burgercats.inoutdelivery.com/xs/1698964257087-Burger%20Kats%20Hamb%20sencilla%20tocineta.jpg",
                          "md": "https://cdn.inoutdelivery.com/burgercats.inoutdelivery.com/md/1698964257118-Burger%20Kats%20Hamb%20sencilla%20tocineta.jpg"
                      }
                  }
              ],
              "destinationLocation": {
                  "lng": -74.06353222,
                  "lat": 4.64998564
              },
              "paymentMethod": {
                  "rid": "-",
                  "code": "cash",
                  "name": "Efectivo"
              },
              "subtotal": 8000,
              "total": 13000,
              "costDelivery": 5000,
              "integrationWebhook": {
                  "uuid": "b07dc454-c8da-49a6-b636-de93fe2da245",
                  "businessId": "burgercats.inoutdelivery.com",
                  "code": "burgercats",
                  "token": "7397c6d1c9c6de3d67c738f12cd93c2a",
                  "logistix": true
              }
            }'`,
          response: `
            {
              "message": "Registro Insertado",
              "uuid": "c67d1d63-bfb0-45d2-926c-da2a0a0dc0b6",
              "consecutive": 6293
            }`,
        },
        {
          title: "Detalle de Servicio",
          method: "GET",
          url: "/v1/service/public/{uuid}",
          request: `curl --location 'https://api.dev.spdy.com.co/v1/service/public/0de6d1ba-16b3-4282-b424-6abd54d78e81' \\ 
--header 'x-access-token: XXXXXXXXXXXXXXXXXXXXXXX'`,
          response: {
  "rid": "#58:3299",
  "deliveryMan": {
    "rid": "#95:90",
    "fullName": "Brayan Marinn",
    "tokenId": "dXTj6VbbSbOGjLcH_esvYN:APA91bFCsr1_0Vy4YKy2HH32rd1gqT4VUIyITwp5sDLrEBcapNSVFSuuD51NK91Fq65_zzuseynBqMqGJycrw9RyjInItqgQhmQyAFU9dU3NX_pr-1sUszw",
    "photo": "https://spdy-mm.s3.amazonaws.com/1193127269/1677722882644-Captura-desde-2023-03-01-00-28-02.png",
    "rates": null
  },
  "consecutive": 6290,
  "deliveryDistance": 1260,
  "logStates": [
    {
      "date": "2025-03-18 09:11:53",
      "minutesAggregate": 1,
      "deliveryman": {
        "tokenId": "dXTj6VbbSbOGjLcH_esvYN:APA91bFCsr1_0Vy4YKy2HH32rd1gqT4VUIyITwp5sDLrEBcapNSVFSuuD51NK91Fq65_zzuseynBqMqGJycrw9RyjInItqgQhmQyAFU9dU3NX_pr-1sUszw",
        "mobile": "573105864885",
        "fullName": "Brayan Marinn",
        "rid": "#95:90",
        "email": "brayan.marin@marketmix.com.co"
      },
      "state": {
        "code": "close",
        "name": "Cerrado",
        "options": { "isClose": true, "isPaid": true },
        "rid": "#87:0"
      },
      "minutesAgo": 0
    },
    {
      "date": "2025-03-18 09:11:52",
      "minutesAggregate": 1,
      "deliveryman": {
        "tokenId": "dXTj6VbbSbOGjLcH_esvYN:APA91bFCsr1_0Vy4YKy2HH32rd1gqT4VUIyITwp5sDLrEBcapNSVFSuuD51NK91Fq65_zzuseynBqMqGJycrw9RyjInItqgQhmQyAFU9dU3NX_pr-1sUszw",
        "mobile": "573105864885",
        "fullName": "Brayan Marinn",
        "rid": "#95:90",
        "email": "brayan.marin@marketmix.com.co"
      },
      "state": {
        "code": "delivered",
        "name": "Entregado",
        "options": { "isDelivered": true, "isPaid": true },
        "rid": "#86:1"
      },
      "minutesAgo": 0
    },
    {
      "date": "2025-03-18 09:11:43",
      "minutesAggregate": 1,
      "deliveryman": {
        "tokenId": "dXTj6VbbSbOGjLcH_esvYN:APA91bFCsr1_0Vy4YKy2HH32rd1gqT4VUIyITwp5sDLrEBcapNSVFSuuD51NK91Fq65_zzuseynBqMqGJycrw9RyjInItqgQhmQyAFU9dU3NX_pr-1sUszw",
        "mobile": "573105864885",
        "fullName": "Brayan Marinn",
        "rid": "#95:90",
        "email": "brayan.marin@marketmix.com.co"
      },
      "state": {
        "code": "arrive",
        "name": "Llegó al destino",
        "options": { "isArrive": true },
        "rid": "#85:0"
      },
      "minutesAgo": 0
    },
    {
      "date": "2025-03-18 09:11:41",
      "minutesAggregate": 1,
      "deliveryman": {
        "tokenId": "dXTj6VbbSbOGjLcH_esvYN:APA91bFCsr1_0Vy4YKy2HH32rd1gqT4VUIyITwp5sDLrEBcapNSVFSuuD51NK91Fq65_zzuseynBqMqGJycrw9RyjInItqgQhmQyAFU9dU3NX_pr-1sUszw",
        "mobile": "573105864885",
        "fullName": "Brayan Marinn",
        "rid": "#95:90",
        "email": "brayan.marin@marketmix.com.co"
      },
      "state": {
        "code": "in-transit",
        "name": "En transito",
        "options": { "startTime": true },
        "rid": "#88:0"
      },
      "minutesAgo": 0
    },
    {
      "date": "2025-03-18 09:11:24",
      "minutesAggregate": 0,
      "deliveryman": {
        "tokenId": "dXTj6VbbSbOGjLcH_esvYN:APA91bFCsr1_0Vy4YKy2HH32rd1gqT4VUIyITwp5sDLrEBcapNSVFSuuD51NK91Fq65_zzuseynBqMqGJycrw9RyjInItqgQhmQyAFU9dU3NX_pr-1sUszw",
        "mobile": "573105864885",
        "fullName": "Brayan Marinn",
        "rid": "#95:90",
        "email": "brayan.marin@marketmix.com.co"
      },
      "state": {
        "code": "arrive-point-sale",
        "name": "Llegó a PDV",
        "options": { "isArrivePointSale": true },
        "rid": "#85:2"
      },
      "minutesAgo": 0
    },
    {
      "date": "2025-03-18 09:11:20",
      "minutesAggregate": 0,
      "deliveryman": {
        "tokenId": "dXTj6VbbSbOGjLcH_esvYN:APA91bFCsr1_0Vy4YKy2HH32rd1gqT4VUIyITwp5sDLrEBcapNSVFSuuD51NK91Fq65_zzuseynBqMqGJycrw9RyjInItqgQhmQyAFU9dU3NX_pr-1sUszw",
        "mobile": "573105864885",
        "fullName": "Brayan Marinn",
        "rid": "#95:90",
        "email": "brayan.marin@marketmix.com.co"
      },
      "state": {
        "code": "accepted",
        "name": "Aceptado",
        "options": { "isAccepted": true },
        "rid": "#86:0"
      },
      "minutesAgo": 0
    },
    {
      "date": "2025-03-18 09:10:38",
      "minutesAggregate": 0,
      "deliveryman": {
        "tokenId": "dXTj6VbbSbOGjLcH_esvYN:APA91bFCsr1_0Vy4YKy2HH32rd1gqT4VUIyITwp5sDLrEBcapNSVFSuuD51NK91Fq65_zzuseynBqMqGJycrw9RyjInItqgQhmQyAFU9dU3NX_pr-1sUszw",
        "mobile": "573105864885",
        "fullName": "Brayan Marinn",
        "rid": "#95:90",
        "email": "brayan.marin@marketmix.com.co"
      },
      "state": {
        "code": "assigned",
        "name": "Asignado",
        "options": {},
        "rid": "#87:2"
      },
      "minutesAgo": 0
    },
    {
      "date": "2025-03-18 09:10:38",
      "minutesAggregate": 0,
      "state": {
        "code": "request",
        "name": "Solicitado",
        "options": { "isRequest": true },
        "rid": "#86:2"
      },
      "minutesAgo": 0
    }
  ],
  "coordsLocation": [-74.06073382292561, 4.660302395157844],
  "total": 0,
  "subtotal": 5000,
  "serviceState": {
    "code": "close",
    "service": {
      "dateLast": "2025-03-18 09:11:52",
      "createdAtService": "2025-03-18T14:10:38.062Z"
    },
    "name": "Cerrado",
    "options": { "isClose": true, "isPaid": true },
    "rid": "#87:0"
  }
}
        },
        {
          title: "Cancelación de Servicio",
          method: "DELETE",
          url: "/v1/service/public",
          request: `curl --location --request DELETE 'https://api.dev.spdy.com.co/v1/service/public' \\
          --header 'x-access-token: XXXXXXXXXXXXXXXXXXXXXXXX' \\
          --header 'Content-Type: application/json' \\
          --data '{
    "consecutive": "6293",
    "externalConsecutive": "7323523",
    "observations": "Motivo de cancelación"
}'
          `,
          response: `{"message": "Servicio cancelado correctamente"}`,
        },
      ],
      states: [
        { code: "request", name: "Solicitado", description: "El servicio ha sido solicitado." },
        { code: "assigned", name: "Asignado", description: "El servicio a sido asignado a un domiciliario." },
        { code: "accepted", name: "Aceptado", description: "El servicio a sido aceptado por el domiciliario." },
        { code: "arrive-point-sale", name: "Llegó a PDV", description: "El domiciliario llego al punto de venta." },
        { code: "in-transit", name: "En transito", description: "El domiciliario esta en camino a entregar el servicio." },
        { code: "arrive", name: "Llegó al destino", description: "El domiciliario llego al lugar de entrega." },
        { code: "delivered", name: "Entregado", description: "El domiciliario a entregado el servicio" },
        { code: "close", name: "Cerrado", description: "El domiciliario a finalizado el servicio" }
      ],
    };
  },
};
</script>

<style scoped>
/* Contenedor principal */
.container {
  width: 70%;
  margin: 20px auto;
  background: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
strong {
  color: #ffffff;
}
h1 {
  text-align: center;
  color: #48FF5E;
  margin-bottom: 20px;
}
h2 {
  color: #48FF5E;
  border-bottom: 2px solid #48FF5E;
  padding-bottom: 5px;
}
/* Código y bloques */
pre {
  background: #2d2d2d;
  padding: 15px;
  border-radius: 5px;
  overflow-x: auto;
  color: white;
  font-size: 14px;
}
@media print {
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
  } 
  .container {
    width: 90% !important;
    /* margin: .5rem;
    padding: 1.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: #2d2d2d; */
  }
}
code {
  white-space: pre-wrap;
  background: #2D2D2D;
  color: #ffffff;
  font-family: "Courier New", monospace;
}
.code2 {
  color: #ffffff;
  font-family: "Courier New", monospace;
  font-weight: 800;
}
p {
  font-size: 16px;
  margin-bottom: 10px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
th, td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}
td{
  color: #ffffff;
}
th {
  background-color: #f4f4f4;
}
/* Responsividad */
@media (max-width: 768px) {
  .container {
    width: 95%;
  }
}
</style>
