<template>
  <div class="container-monitoring" v-if="service">
    <!-- Mapa de Google -->
    <el-card class="service-card" style="padding: 0">
      <GoogleMap
        :key="mapKey"
        mapHeight="85vh"
        from="monitoring"
        themeMap="true"
        watcher="0"
        :market="
          deliverymanPosition
            ? deliverymanPosition.location
            : ''
        "
        :destination="gMap"
        :origin="pdvZone"
      />
    </el-card>

    <!-- Bottom Sheet -->
    <div
      class="bottom-sheet"
      :class="{ open: isSheetOpen }"
      @touchstart="startTouch"
      @touchmove="moveTouch"
    >
      <!-- Indicador para arrastrar -->
      <div class="drag-indicator-container">
        <div class="drag-indicator" @click="toggleBottomSheet"></div>
      </div>

      <div class="sheet-content">
        <div class="timeline-horizontal">
          <div
            class="contenedor-timeline"
            v-for="(step, index) in steps"
            :key="index"
            :class="['timeline-step', getStepClass(step.state, index)]"
          >
            <div class="contenedor-dos">
              <!-- Contenedor del icono y la línea -->
              <div class="timeline-icon-container">
                <div :class="['timeline-icon', getIconClass(index)]">
                  {{ step.icon }}
                </div>
              </div>
              <p class="timeline-label">
                {{ step.label }}
              </p>
            </div>
            <!-- Línea de progreso horizontal solo si no es el último paso -->
            <div
              v-if="index !== steps.length - 1"
              :class="['timeline-line-horizontal', getLineClass(index)]"
            ></div>
          </div>
        </div>

        <!-- Tarjeta de información de la tienda y domiciliario -->
        <div class="info-card">
          <h3 class="info-card-title">Información de la Orden</h3>
          <div class="info-card-body">
            <div class="info-item">
              <strong>Nombre de la Tienda:</strong>
              <p>{{ service.commerce }}</p>
            </div>
            <div class="info-item">
              <strong>Domiciliario:</strong>
              <p>
                {{
                  service.deliveryMan
                    ? service.deliveryMan.fullName
                    : "No hay domiciliario"
                }}
                🛵
              </p>
              <p>
                {{
                  service.deliveryMan
                    ? service.deliveryMan.mobile
                    : "No hay domiciliario"
                }}
                📞
              </p>
            </div>
            <div class="info-item">
              <strong>Total a Pagar:</strong>
              <p>${{ service.subtotal }}</p>
            </div>
            <div class="info-item">
              <strong>Método de Pago:</strong>
              <p>💵 {{ service.paymentMethod.name }}</p>
            </div>
          </div>
        </div>

        <div class="info-card">
          <h3 class="info-card-title">Productos</h3>
          <div class="info-card-body">
            <div
              class="product-item"
              v-for="(product, index) in service.items"
              :key="index"
            >
              <span class="product-name">{{ product.description }}</span>
              <span class="product-value">{{ product.subtotal }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from "../../components/base/gmap/gmap.component.vue";
import { Monitor } from "@/services/monitor";
import colors from "./colorsPdv.json";
const ModelMonitor = new Monitor();

export default {
  name: "ServiceView",
  components: {
    GoogleMap,
  },
  data() {
    return {
      service: null,
      kmlZone: null,
      gMap: null,
      pdvZone: null,
      isSheetOpen: true, // Estado para controlar si el Bottom Sheet está abierto
      startY: 0, // Coordenada inicial del toque
      currentY: 0, // Coordenada actual del toque
      products: [
        { name: "Producto 1", value: "$10.00" },
        { name: "Producto 2", value: "$20.00" },
        { name: "Producto 3", value: "$30.00" },
      ],
      steps: [
        { label: "Asignado", icon: "🎁", state: "assigned" },
        { label: "Llegó a PDV", icon: "🏬", state: "arrive-point-sale" },
        { label: "En transito", icon: "🚚", state: "in-transit" },
        { label: "En Destino", icon: "🏠", state: "arrive" },
        { label: "Entregado", icon: "📦", state: "delivered" },
      ],
      currentStatus: null,
      // Estados que deben rellenar el primer paso
      fillingFirstStepStates: ["request", "assigned", "accepted"],
      fillingThirdStepStates: ["in-transit", "out-of-range"],
      // Estados que deben rellenar el último paso
      fillingLastStepStates: ["delivered", "delivered-ex", "close"],
      timeoutId: null, // Para guardar el ID del timeout
      mapKey: 0,
      namePdv: "",
      deliverymanPosition: "",
    };
  },
  mounted() {
    this.namePdv = this.$route.params.namePdv;
    this.applyTheme(this.namePdv);
    const uuid = this.$route.params.uuid;
    if (uuid) {
      this.getService(uuid); // Cargar los datos al inicio
    }
    this.reloadData(); // Comienza el proceso de recarga
  },
  beforeUnmount() {
    // Limpiar el timeout cuando el componente se destruya
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },
  methods: {
    applyTheme(business) {
      const root = document.documentElement; // Selecciona el elemento raíz
      const theme = colors[business] || colors["spdy"]; // Fallback si el negocio no existe

      // Establece las variables CSS
      root.style.setProperty("--primary-color", theme.primary);
      root.style.setProperty("--secondary-color", theme.secondary);
    },
    calculateCenter(location1, location2, location3) {
      const latitudes = [location1.lat, location2.lat, location3.lat];
      const longitudes = [location1.lng, location2.lng, location3.lng];

      // Calcular la latitud y longitud promedio
      const lat = latitudes.reduce((acc, val) => acc + val, 0) / 3;
      const lng = longitudes.reduce((acc, val) => acc + val, 0) / 3;

      return { lat, lng };
    },
    async getService(uuid) {
      const data = await ModelMonitor.getServiceByUuid(uuid);
      this.service = data.data;
      this.deliverymanPosition =  this.service.deliveryManPosition || "";
      this.currentStatus = this.service.logStates[0].state.code;
      if(this.currentStatus == "close")  this.deliverymanPosition = "";
      this.gMap = [{ location: this.service.destinationLocation.coordinates }];
      this.pdvZone = [{ location: this.service.locationPdv.coordinates }];
      this.mapKey += 1;
    },
    toggleBottomSheet() {
      this.isSheetOpen = !this.isSheetOpen;
    },
    startTouch(event) {
      this.startY = event.touches[0].clientY;
    },
    moveTouch(event) {
      this.currentY = event.touches[0].clientY;
      const diff = this.startY - this.currentY;
      if (diff > 100) {
        this.isSheetOpen = true; // Abrir si se desliza hacia arriba
      } else if (diff < -100) {
        this.isSheetOpen = false; // Cerrar si se desliza hacia abajo
      }
    },
    // Función para obtener la clase de un paso
    getStepClass(stepState, index) {
      const statesOrder = [
        "assigned",
        "arrive-point-sale",
        "in-transit",
        "arrive",
        "delivered",
      ];

      // Encontrar el índice del estado actual en la lista
      let currentIndex = statesOrder.findIndex(
        (state) =>
          state === this.currentStatus ||
          (this.fillingFirstStepStates.includes(this.currentStatus) &&
            state === "assigned") ||
          (this.fillingThirdStepStates.includes(this.currentStatus) &&
            state === "in-transit") ||
          (this.fillingLastStepStates.includes(this.currentStatus) &&
            state === "delivered")
      );

      // Colorea de azul si el índice del paso es menor o igual al estado actual
      return index <= currentIndex ? "step-active" : "step-gray";
    },

    // Función para obtener la clase del icono
    getIconClass(index) {
      const statesOrder = [
        "assigned",
        "arrive-point-sale",
        "in-transit",
        "arrive",
        "delivered",
      ];

      // Encontrar el índice del estado actual en la lista
      let currentIndex = statesOrder.findIndex(
        (state) =>
          state === this.currentStatus ||
          (this.fillingFirstStepStates.includes(this.currentStatus) &&
            state === "assigned") ||
          (this.fillingThirdStepStates.includes(this.currentStatus) &&
            state === "in-transit") ||
          (this.fillingLastStepStates.includes(this.currentStatus) &&
            state === "delivered")
      );

      // Los iconos hasta el estado actual se pintan de azul
      return index <= currentIndex ? "icon-active" : "icon-gray";
    },

    // Función para obtener la clase de la línea
    getLineClass(index) {
      const statesOrder = [
        "assigned",
        "arrive-point-sale",
        "in-transit",
        "arrive",
        "delivered",
      ];

      // Encontrar el índice del estado actual en la lista
      let currentIndex = statesOrder.findIndex(
        (state) =>
          state === this.currentStatus ||
          (this.fillingFirstStepStates.includes(this.currentStatus) &&
            state === "assigned") ||
          (this.fillingThirdStepStates.includes(this.currentStatus) &&
            state === "in-transit") ||
          (this.fillingLastStepStates.includes(this.currentStatus) &&
            state === "delivered")
      );

      // Las líneas hasta el estado actual se pintan de azul
      return index < currentIndex ? "line-active" : "line-gray";
    },
    reloadData() {
      // Recargar los datos cada 5 segundos
      this.timeoutId = setTimeout(() => {
        const uuid = this.$route.params.uuid;
        if (uuid) {
          this.getService(uuid); // Llamar a la función para obtener los datos nuevamente
        }
        this.reloadData(); // Volver a ejecutar el timeout para seguir recargando
      }, 10000); // 5000 milisegundos = 5 segundos
    },
  },
};
</script>

<style scoped>
:root {
  --primary-color: #7300b9; /* Default por si no hay JS */
  --secondary-color: #6c757d;
}


/* Estilos para los pasos */
.step-active {
  color: var(--primary-color);
  font-weight: bold;
}

.step-gray {
  color: var(--secondary-color);
}

/* Estilos para los iconos */
.icon-active {
  border: 1px solid var(--primary-color);
  outline: 1px solid var(--primary-color);
  outline-offset: 3px;
}

.icon-gray {
  color: var(--secondary-color);
}

/* Estilos para las líneas de progreso */
.line-active {
  background-color: var(--primary-color) !important;
}

.line-gray {
  background-color: var(--secondary-color) !important;
  height: 2px;
  width: 100%;
}

h2 {
  color: black;
}
.container-monitoring {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.service-card {
  width: 100%;
  height: 85vh;
  padding: 0;
  margin: 0;
}
.service-card >>> .el-card__body {
  padding: 0 !important;
}

/* Estilos para el Bottom Sheet */
.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20vh; /* Altura cuando está cerrado */
  background-color: #f3f1f1;
  transition: height 0.3s ease-in-out;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Ajustes cuando el Bottom Sheet está abierto */
.bottom-sheet.open {
  background-color: #f3f1f1;
  height: 80vh; /* Altura cuando está abierto */
  overflow-y: auto; /* Permitir desplazamiento vertical */
}

.sheet-content {
  padding: 0 20px; /* Espaciado interno */
}

/* Indicador para arrastrar */
.drag-indicator-container {
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0; /* Fondo opcional */
  padding: 10px 0;
}

.drag-indicator {
  width: 50px;
  height: 5px;
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.timeline-horizontal {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
  margin: 20px 0;
  justify-content: center;
  margin-bottom: 52px;
}

.contenedor-timeline {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  position: relative;
  flex-grow: 1; /* Esto permite que se estiren los contenedores para que se espacien bien */
}

.timeline-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.timeline-icon {
  width: 40px; /* Ajusta el tamaño de los iconos según la pantalla */
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px; /* Tamaño del icono */
  position: relative;
  z-index: 2; /* Para asegurarse que el icono esté por encima de la línea */
}

.timeline-line-horizontal {
  height: 2px;
  background-color: #ccc;
  z-index: 1;
  width: 55px;
  margin: 0 4px;
}

.timeline-label {
  text-align: center;
  white-space: normal;
  word-break: break-word;
  font-size: 14px;
  position: absolute;
  top: 30px;
  width: 80px;
}
.timeline-step {
  position: relative;
  display: flex;
  width: 100%;
  max-width: min-content;
}
.contenedor-dos {
  position: relative;
  display: flex;
  justify-content: center;
}

/* Estilos para la tarjeta de información */
.info-card {
  background-color: #ffffff !important;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  margin-bottom: 20px;
}

.info-card-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.info-card-body {
  display: flex;
  flex-direction: column;
}

.product-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.product-item:last-child {
  border-bottom: none;
}

.product-name {
  font-weight: bold;
}

.product-value {
  color: #000;
}

.info-item {
  margin-bottom: 8px;
}

.info-item strong {
  display: block;
  color: #555; /* Color para las etiquetas */
  font-size: 14px; /* Tamaño de fuente más pequeño */
}

.info-item p {
  margin: 3px 0;
  color: #333; /* Color del texto */
  font-size: 14px; /* Tamaño de fuente más pequeño */
}
@media (max-width: 460px) {
  .timeline-line-horizontal {
    width: 35px;
  }

  .timeline-label {
    font-size: 10px;
  }
}
@media (max-width: 350px) {
  .timeline-line-horizontal {
    width: 30px;
  }

  .timeline-label {
    font-size: 10px;
    width: 50px;
  }
}
@media (max-width: 768px) {
  .timeline-icon {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}
</style>
