var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('span',{staticStyle:{"color":"#fff","display":"none"}},[_vm._v(" "+_vm._s(_vm.toggleWatch = _vm.$store.state.toggle)+" ")]),(_vm.$route.name == 'viewService')?_c('el-container',{staticStyle:{"background-color":"black"}},[_c('ServiceView')],1):_vm._e(),(_vm.$route.name == 'integrationDocument')?_c('el-container',{staticStyle:{"background-color":"#2D2D2D","height":"auto"}},[_c('IntegrationDocument')],1):_c('el-container',[_c('el-container',[(_vm.isInLoginView () == false  && _vm.$route.name != 'ServiceView')?_c('el-header',{staticStyle:{"height":"62px"}},[_c('NavBar')],1):_vm._e(),_c('el-main',{staticStyle:{"padding":"0px"},style:({'background-color': _vm.isInLoginView() == false ? '#262625' : 'rgb(29, 29, 29)'})},[(_vm.isInLoginView () == false && _vm.$route.name != 'ServiceView')?_c('el-col',{staticClass:"hidden-xs-only sidebar-col",class:{collasep:_vm.isCollasep},attrs:{"lg":_vm.isCollasep ? 1: 4,"sm":_vm.isCollasep ? 1: 4}},[(this.stateGlobalRols != 'sin definir')?_c('Sidebar',{staticClass:"hidden-xs-only",attrs:{"modehv":"vertical"}}):_vm._e()],1):_vm._e(),_c('el-col',[(this.stateGlobalRols == 'sin definir')?_c('el-alert',{attrs:{"title":"Este domiciliario no tiene Ningun permiso. Contactate con tu administrador","type":"warning"}}):_vm._e()],1),_c('el-col',{staticClass:"spdy-wrapper",class:{
            'collapse': _vm.$store.state.isLogin == true ? _vm.isCollasep : false,
            'container-tables': !_vm.isInLoginView()
            },style:({
           'float': 'right',
            width: _vm.content_width == 0 ? '100%' : _vm.content_width+'px',
           }),attrs:{"lg":_vm.isInLoginView() == false ? 24 : (_vm.$store.state.toggle ? 24: 10)}},[_c('router-view')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }